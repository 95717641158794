<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      :stroke="color"
      d="M14.5 1c-.234 0-.442.092-.622.275L1.27 14.085c-.18.184-.271.395-.271.633s.09.45.27.633l1.353 1.374c.18.183.388.275.622.275.235 0 .442-.092.623-.275L14.5 5.921l10.632 10.804c.18.183.388.275.623.275.234 0 .441-.092.622-.275l1.352-1.374c.18-.184.271-.395.271-.633s-.09-.449-.27-.632L15.121 1.275C14.942 1.092 14.734 1 14.5 1z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
